class ScrollSpy {
    constructor(selector) {
        this.selector = selector;
        this.scroll_elements = this.selector.querySelectorAll('[data-element="menu"]');

        this._observerCallback = this._observerCallback.bind(this);

        if(this.scroll_elements.length > 0) {
            const observer = new IntersectionObserver(this._observerCallback, {root: null, rootMargin: "0% 0% -65% 0%"});
            this.scroll_elements.forEach(el => {
                let section_id = el.getAttribute('href');
                let section = document.querySelector(section_id);
                if(section !== null) {
                    observer.observe(section);
                }
            })
        }

    }

    _observerCallback(entries, observer) {
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                let section_id = entry.target.id;
                this.scroll_elements.forEach(el => {
                    let target = el.getAttribute('href');
                    if(target === '#'+section_id) {
                        el.classList.add('active');
                        if (window.innerWidth <= 768) {
                            el.scrollIntoView({inline: 'center'});
                        }
                    } else {
                        el.classList.remove('active');
                    }
                });
            }
        })
    }

}

export default ScrollSpy;