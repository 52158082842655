class Favourites {
    constructor(selector, options) {
        this.selector = selector;
        this.options = options;
        this.url = '/wp-admin/admin-ajax.php';
        this.actions = {
            add: 'nt_add_favourites_product',
            remove: 'nt_remove_favourites_product',
            clear: 'nt_clear_favourites'
        }

        this.removeFromFavourites = this.removeFromFavourites.bind(this);
        this.addToFavourites = this.addToFavourites.bind(this);
        this.addOrRemoveFromFavourites = this.addOrRemoveFromFavourites.bind(this);
        this.clearFavourites = this.clearFavourites.bind(this);

        this.productContainer = this.selector.querySelector('[data-element="product-container"]');
        this.clearButton = this.selector.querySelector('[data-element="clear"]');
        if (this.clearButton !== null) {
            this.clearButton.addEventListener('click', this.clearFavourites);
        }
        this._connectActions();
    }

    removeFromFavourites(event) {
        event.preventDefault();
        let button = event.target.closest('[data-id]');
        let product_id = button.getAttribute('data-id');
        if (product_id !== null && typeof product_id !== 'undefined') {
            this._removeProduct(product_id).then(res => {
                if (res) button.classList.remove('added_to_list');
                this.options.afterRemove(res, product_id);
            });
        }
    }

    addToFavourites(event) {
        event.preventDefault();
        let button = event.target.closest('[data-id]');
        let product_id = button.getAttribute('data-id');
        if (product_id !== null && typeof product_id !== 'undefined') {
            this._addProduct(product_id).then(res => {
                if (res) button.classList.add('added_to_list')
                this.options.afterAdd(res, product_id);
            });
        }
    }

    addOrRemoveFromFavourites(event) {
        event.preventDefault();
        let button = event.target.closest('[data-id]');
        let product_id = button.getAttribute('data-id');
        if (product_id !== null && typeof product_id !== 'undefined') {
            if (button.classList.contains('added_to_list')) {
                this._removeProduct(product_id).then(res => {
                    if (res) button.classList.remove('added_to_list');
                    this.options.afterRemove(res, product_id);
                });
            } else {
                this._addProduct(product_id).then(res => {
                    if (res) button.classList.add('added_to_list')
                    this.options.afterAdd(res, product_id);
                });
            }
        }
    }

    _addProduct(product_id) {
        return this._getFromApi('add', product_id).then(response => {
            if (response.status === 'success') {
                this._refreshSide(response.mini);
                this.options.updateCount(response.count);
                return true;
            } else {
                console.log(response);
                return false;
            }
        }).catch(error => {
            console.log(error);
            return false;
        });
    }

    clearFavourites(event) {
        event.preventDefault();
        this._getFromApi('clear').then(response => {
            if (response.status === 'success') {
                this._refreshSide(response.mini);
                this.options.updateCount(response.count);
                this.options.afterClear();
            } else {
                console.log(response);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    _removeProduct(product_id) {
        return this._getFromApi('remove', product_id).then(response => {
            if (response.status === 'success') {
                this._refreshSide(response.mini);
                this.options.updateCount(response.count);
                return true;
            } else {
                console.log(response);
                return false;
            }
        }).catch(error => {
            console.log(error);
            return false;
        });
    }

    _refreshSide(favourites) {
        this.productContainer.innerHTML = '';
        this.productContainer.innerHTML = favourites;
        this._connectActions();
    }

    _connectActions() {
        let remove = this.productContainer.querySelectorAll('[data-element="remove"]');
        if (remove.length > 0) {
            remove.forEach(r => {
                r.addEventListener('click', this.removeFromFavourites);
            });
        }
    }

    _getFromApi(action, product_id = null) {
        const body_data = new FormData();
        body_data.append('action', this.actions[action]);
        if (product_id !== null) body_data.append('product_id', product_id);

        const body_params = new URLSearchParams(body_data);
        return fetch(this.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: body_params
        }).then((response) => {
            if (!response.ok) {
                return response.json().then(function (response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

}

export default Favourites;