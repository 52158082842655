class TpayCalculator {
    constructor(selector) {
        this.selector = selector;

        this.button = this.selector.querySelector('.js-tpay-calculator-button');
        this.container = this.selector.querySelector('.tpay-calculator');
        this.closeButton = this.selector.querySelector('.tpay-calculator-close-button');

        this._onClickButton = this._onClickButton.bind(this);
        this._handleOutsideClick = this._handleOutsideClick.bind(this);
        this._onClickButtonClose = this._onClickButtonClose.bind(this);

        this.button.addEventListener('click', this._onClickButton);
        this.closeButton.addEventListener('click', this._onClickButtonClose);

    }

    _onClickButton(e) {
        e.preventDefault();
        if(!this.container.classList.contains('active')){
            this.container.style.display = 'block';
            this.closeButton.style.display = 'block';
            this.container.classList.add('active');
            document.addEventListener('click', this._handleOutsideClick);
        } else {
            this.container.style.display = 'none';
            this.container.classList.remove('active');
            document.removeEventListener('click', this._handleOutsideClick);
        }
    }

    _handleOutsideClick(e) {
        if (!this.container.contains(e.target) && !this.button.contains(e.target)) {
            this.container.style.display = 'none';
            this.closeButton.style.display = 'none';
            this.container.classList.remove('active');
            document.removeEventListener('click', this._handleOutsideClick);
        }
    }

    _onClickButtonClose(e) {
        e.preventDefault();
        if(this.closeButton.contains(e.target)) {
            this.container.style.display = 'none';
            this.closeButton.style.display = 'none';
            this.container.classList.remove('active');
        }
    }

}
export default TpayCalculator;