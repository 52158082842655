import { Form, initMap } from '@netivo/base-scripts';
import Pristine from "pristinejs/src/pristine";

import HiddenFilters from "../hiddenFilters";
import FilterAttributes from "../filterAttributes";
import Favourites from "../favourites";
import Comparison from "../comparison";
import Cart from "../cart";
import ProductBox from "../productBox";
import subMenuImage from "../subMenuImage";
import ScrollSpy from "../scrollSpy";
import Menu from "../menu";
import TpayCalculator from "../tpayCalculator";

window.initMap = initMap;

let filters_attributes = document.querySelectorAll('.js-attribute-filter');
if (filters_attributes.length > 0) {
    filters_attributes.forEach(fsa => {
        new FilterAttributes(fsa);
    });
}

let side_favourites = document.querySelector('.js-favourites');
let fav = null;
if (side_favourites !== null) {
    fav = new Favourites(side_favourites, {
        updateCount: (count) => {
            let fav_counter = document.querySelector('.js-favourites-count');
            if (fav_counter !== null) {
                if (count > 0) {
                    fav_counter.innerHTML = count;
                    fav_counter.style.display = 'flex';
                } else {
                    fav_counter.innerHTML = 0;
                    fav_counter.style.display = 'none';
                }
            }
        },
        afterRemove: (result, product_id) => {
            if (result) {
                let product_fav = document.querySelectorAll('.js-add-to-favourites[data-id="' + product_id + '"]');
                if (product_fav.length > 0) {
                    product_fav.forEach(pf => {
                        pf.classList.remove('added_to_list');
                    })
                }
                if (typeof window.collectionShadow !== "undefined") {
                    let coll_product_fav = window.collectionShadow.querySelectorAll('.js-add-to-favourites[data-id="' + product_id + '"]');
                    if (coll_product_fav.length > 0) {
                        coll_product_fav.forEach(pf => {
                            pf.classList.remove('added_to_list');
                        })
                    }
                }
            }
        },
        afterAdd: (result, product_id) => {
            if (result) {
                let product_fav = document.querySelectorAll('.js-add-to-favourites[data-id="' + product_id + '"]');
                if (product_fav.length > 0) {
                    product_fav.forEach(pf => {
                        pf.classList.add('added_to_list');
                    })
                }
                if (typeof window.collectionShadow !== "undefined") {
                    let coll_product_fav = window.collectionShadow.querySelectorAll('.js-add-to-favourites[data-id="' + product_id + '"]');
                    if (coll_product_fav.length > 0) {
                        coll_product_fav.forEach(pf => {
                            pf.classList.add('added_to_list');
                        })
                    }
                }
            }
        },
        afterClear: () => {
            let add_to_fav = document.querySelectorAll('.js-add-to-favourites');
            if (add_to_fav.length > 0) {
                add_to_fav.forEach(af => {
                    af.classList.remove('added_to_list');
                });
            }
            if (typeof window.collectionShadow !== "undefined") {
                let coll_add_to_fav = window.collectionShadow.querySelectorAll('.js-add-to-favourites');
                if (coll_add_to_fav.length > 0) {
                    coll_add_to_fav.forEach(af => {
                        af.classList.remove('added_to_list');
                    });
                }
            }
        }
    });
    let add_to_fav = document.querySelectorAll('.js-add-to-favourites');
    if (add_to_fav.length > 0) {
        add_to_fav.forEach(af => {
            af.addEventListener('click', fav.addOrRemoveFromFavourites);
        });
    }
}

let bar_comparison = document.querySelector('.js-comparison');
let comp = null;
if (bar_comparison !== null) {
    comp = new Comparison(bar_comparison, {
        afterRemove: (result, product_id) => {
            if (result) {
                let product_comp = document.querySelectorAll('.js-add-to-compare[data-id="' + product_id + '"]');
                if (product_comp.length > 0) {
                    product_comp.forEach(pc => {
                        pc.classList.remove('added_to_list');
                    })
                }
                if (typeof window.collectionShadow !== "undefined") {
                    let coll_product_comp = window.collectionShadow.querySelectorAll('.js-add-to-compare[data-id="' + product_id + '"]');
                    if (coll_product_comp.length > 0) {
                        coll_product_comp.forEach(pc => {
                            pc.classList.remove('added_to_list');
                        })
                    }
                }
            }
        },
        afterAdd: (result, product_id) => {
            if (result) {
                let product_comp = document.querySelectorAll('.js-add-to-compare[data-id="' + product_id + '"]');
                if (product_comp.length > 0) {
                    product_comp.forEach(pc => {
                        pc.classList.add('added_to_list');
                    })
                }
                if (typeof window.collectionShadow !== "undefined") {
                    let coll_product_comp = window.collectionShadow.querySelectorAll('.js-add-to-compare[data-id="' + product_id + '"]');
                    if (coll_product_comp.length > 0) {
                        coll_product_comp.forEach(pc => {
                            pc.classList.add('added_to_list');
                        })
                    }
                }
            }
        },
        afterClear: () => {
            let add_to_comp = document.querySelectorAll('.js-add-to-compare');
            if (add_to_comp.length > 0) {
                add_to_comp.forEach(ac => {
                    ac.classList.remove('added_to_list');
                });
            }
            if (typeof window.collectionShadow !== "undefined") {
                let coll_add_to_comp = window.collectionShadow.querySelectorAll('.js-add-to-compare');
                if (coll_add_to_comp.length > 0) {
                    coll_add_to_comp.forEach(ac => {
                        ac.classList.remove('added_to_list');
                    });
                }
            }
            if (window.is_template_compare === true) window.location.href = '/';
        },
        showError: (error) => {
            const errorMsgDiv = document.getElementById('comparison__msg');
            if (errorMsgDiv) {
                errorMsgDiv.innerHTML = error;
                errorMsgDiv.classList.add('error-up');
                setTimeout(() => {
                    errorMsgDiv.classList.remove('error-up');
                }, 5000);
            }
        },
        updateCount: (count) => {
            let comp_counter = document.querySelector('.js-bar-comparison-count');
            if (comp_counter !== null) {
                comp_counter.innerHTML = count;
            }
        }
    });
    let add_to_comp = document.querySelectorAll('.js-add-to-compare');
    if (add_to_comp.length > 0) {
        add_to_comp.forEach(ac => {
            ac.addEventListener('click', comp.addOrRemoveFromComparison);
        });
    }
}

let mini_cart = document.querySelector('.js-mini-cart');
let cart = null;
if (mini_cart !== null) {
    cart = new Cart(mini_cart, {
        updateCount: (count) => {
            let cart_counter = document.querySelector('.js-cart-count');
            if (cart_counter !== null) {
                if (count > 0) {
                    cart_counter.innerHTML = count;
                    cart_counter.style.display = 'flex';
                } else {
                    cart_counter.innerHTML = 0;
                    cart_counter.style.display = 'none';
                }
            }
            let mini_cart_counter = document.querySelector('.js-mini-cart-count');
            if (mini_cart_counter !== null) {
                count = parseInt(count);
                let text = count + ' produktów';
                if (count % 10 > 1 && count % 10 < 5 && ![11, 12, 13, 14].includes(count % 100)) {
                    text = count + ' produkty';
                } else if (count === 1) {
                    text = count + ' produkt';
                }
                mini_cart_counter.innerHTML = text;
            }
        },
        showSuccess: (modal) => {
            let modal_cont = document.createElement('div');
            modal_cont.innerHTML = modal;
            let buttons = modal_cont.querySelectorAll('[data-element="close"]');
            if (buttons.length > 0) {
                buttons.forEach(bt => {
                    bt.addEventListener('click', e => {
                        e.preventDefault();
                        modal_cont.remove();
                    })
                });
            }
            let atc = modal_cont.querySelectorAll('.ajax_add_to_cart');
            if (atc.length > 0) {
                atc.forEach(ac => {
                    ac.addEventListener('click', e => {
                        e.preventDefault();
                        modal_cont.remove();
                    })
                    ac.addEventListener('click', cart.addToCart);
                });
            }
            document.body.appendChild(modal_cont);
            setTimeout(() => {
                modal_cont.remove();
            }, 15000);
        },
        showError: (error) => {
            /* @todo wyświetlanie błędu w modalu */
            console.log(error);
        }
    });
    let add_to_cart = document.querySelectorAll('.ajax_add_to_cart');
    if (add_to_cart.length > 0) {
        add_to_cart.forEach(ac => {
            ac.addEventListener('click', cart.addToCart);
        });
    }
}

new ProductBox(document, fav, comp, cart);
if (typeof window.collectionShadow !== "undefined") {
    if (fav !== null) {
        let coll_add_to_fav = window.collectionShadow.querySelectorAll('.js-add-to-favourites');
        if (coll_add_to_fav.length > 0) {
            coll_add_to_fav.forEach(af => {
                af.addEventListener('click', fav.addOrRemoveFromFavourites);
            });
        }
    }
    if (comp !== null) {
        let coll_add_to_comp = window.collectionShadow.querySelectorAll('.js-add-to-compare');
        if (coll_add_to_comp.length > 0) {
            coll_add_to_comp.forEach(ac => {
                ac.addEventListener('click', comp.addOrRemoveFromComparison);
            });
        }
    }
    if (cart !== null) {
        let coll_add_to_cart = window.collectionShadow.querySelectorAll('.ajax_add_to_cart');
        if (coll_add_to_cart.length > 0) {
            coll_add_to_cart.forEach(ac => {
                ac.addEventListener('click', cart.addToCart);
            });
        }
    }
    new ProductBox(window.collectionShadow, fav, comp, cart);
}


// list/grid

document.addEventListener('DOMContentLoaded', function () {
    const listViewButton = document.getElementById('list-view');
    const gridViewButton = document.getElementById('grid-view');
    const productWrapper = document.querySelector('.productList__wrap--main')
    if (listViewButton && gridViewButton && productWrapper) {
        function updateView(viewType) {
            // if (window.innerWidth <= 768) {
                if (viewType === 'list') {
                    productWrapper.classList.add('list-view');
                    productWrapper.classList.remove('grid-view');
                    listViewButton.classList.add('active');
                    gridViewButton.classList.remove('active');
                    updateUrlParam('view', 'list');
                } else {
                    productWrapper.classList.add('grid-view');
                    productWrapper.classList.remove('list-view');
                    gridViewButton.classList.add('active');
                    listViewButton.classList.remove('active');
                    updateUrlParam('view', 'grid');
                }
            // }
        }

        function updateUrlParam(key, value) {
            // if (window.innerWidth <= 768) {
                const url = new URL(window.location.href);
                url.searchParams.set(key, value);
                window.history.pushState({}, '', url);
            // }
        }

        listViewButton.addEventListener('click', function () {
            updateView('list');
        });

        gridViewButton.addEventListener('click', function () {
            updateView('grid');
        });

        const urlParams = new URLSearchParams(window.location.search);
        const view = urlParams.get('view');
        if (view === 'list') {
            updateView('list');
        } else {
            updateView('grid'); // Set grid view as default
        }
    }
});


// mobile filters

document.addEventListener('DOMContentLoaded', function () {
    const filterTrigger = document.getElementById('mobileFilters__trigger');
    const closeFilterTrigger = document.getElementById('closeFilters');
    const sidebarWrap = document.querySelector('.sidebar__wrap');

    if (filterTrigger && sidebarWrap) {
        filterTrigger.addEventListener('click', function () {
            sidebarWrap.classList.toggle('active');
        });
    }

    if (closeFilterTrigger && sidebarWrap) {
        closeFilterTrigger.addEventListener('click', function () {
            sidebarWrap.classList.remove('active');
        });
    }
});

const mainCategories = document.querySelectorAll('li > a');
mainCategories.forEach(category => {
    category.addEventListener('click', function (event) {
        const subList = this.nextElementSibling;
        const parentLi = this.parentElement;

        if (subList && subList.classList.contains('sub-list')) {
            mainCategories.forEach(innerCategory => {
                if (innerCategory !== this) {
                    innerCategory.parentElement.classList.remove('open');
                }
            });
            parentLi.classList.toggle('open');
            event.preventDefault();
        }
    });
});

// to top
const toTopButton = document.getElementById('toTop');
if (toTopButton) {
    window.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
            toTopButton.style.display = 'block';
        } else {
            toTopButton.style.display = 'none';
        }
    });
}

// menu nav header

document.addEventListener('DOMContentLoaded', function () {
    // Select all li elements with the .menu-item-has-children class
    var menuItems = document.querySelectorAll('.mainHeader__nav .menu-item-has-children');
    var currentlyOpenItem = null;
    menuItems.forEach(function (menuItem) {
        var firstLink = menuItem.querySelector('a');
        firstLink.addEventListener('click', function (event) {
            event.preventDefault();
            if (currentlyOpenItem && currentlyOpenItem !== menuItem) {
                currentlyOpenItem.classList.remove('open-item');
                // let sub_menu_cur = currentlyOpenItem.closest('.sub-menu');
                // if(sub_menu_cur !== null) sub_menu.classList.remove('sub-menu-open');
            }
            menuItem.classList.toggle('open-item');
            currentlyOpenItem = menuItem.classList.contains('open-item') ? menuItem : null;
            let sub_menu = menuItem.closest('.sub-menu');
            if(sub_menu !== null) {
                if(currentlyOpenItem !== null) {
                    sub_menu.classList.add('sub-menu-open');
                } else {
                    sub_menu.classList.remove('sub-menu-open');
                }
            }
        });
    });
});

let mobileMenu = document.querySelector('.mobileNav__inner');
if(mobileMenu !== null) {
    console.log(mobileMenu);
    new Menu(mobileMenu);
}
document.addEventListener('DOMContentLoaded', function () {
    var trigger = document.getElementById('mobile__nav--trigger');
    var mobileNavWrap = document.getElementById('mobileNav__wrap');
    var body = document.body;

    if (trigger && mobileNavWrap) {
        trigger.addEventListener('click', function () {
            mobileNavWrap.classList.toggle('open');
            trigger.classList.toggle('active');
            body.classList.toggle('bg_fade');
            body.classList.toggle('noscroll');
        });
        document.addEventListener('click', function (e) {
            if (!mobileNavWrap.contains(e.target) && mobileNavWrap.classList.contains('open') && e.target !== trigger) {
                mobileNavWrap.classList.remove('open');
                trigger.classList.remove('active');
                body.classList.remove('bg_fade');
                body.classList.remove('noscroll');
            }
        });
    }
});


document.addEventListener('DOMContentLoaded', () => {
    const cartTrigger = document.getElementById('cart_trigger');
    const miniCartWrap = document.getElementById('miniCart__wrap');
    const loginRegisterTrigger = document.querySelector('#loginRegister--trigger');
    const sideLoginWrap = document.querySelector('#sideLogin__wrap');
    const closeButtons = document.querySelectorAll('.side__box--close');
    const body = document.body;

    const toggleElement = (element) => {
        if (element) {
            element.classList.toggle('open');
            body.classList.toggle('noscroll');
        }
    };

    const closeElement = (element) => {
        if (element && element.classList.contains('open')) {
            element.classList.remove('open');
            body.classList.remove('noscroll');
        }
    };

    if (cartTrigger && miniCartWrap) {
        cartTrigger.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleElement(miniCartWrap);
        });
    }

    if (loginRegisterTrigger && sideLoginWrap) {
        loginRegisterTrigger.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleElement(sideLoginWrap);
        });
    }

    closeButtons.forEach(btn => {
        btn.addEventListener('click', (e) => {
            e.stopPropagation();
            closeElement(miniCartWrap);
            closeElement(sideLoginWrap);
        });
    });

    document.addEventListener('click', (event) => {
        if (miniCartWrap && !miniCartWrap.contains(event.target) && event.target !== cartTrigger) {
            closeElement(miniCartWrap);
        }
        if (sideLoginWrap && !sideLoginWrap.contains(event.target) && event.target !== loginRegisterTrigger) {
            closeElement(sideLoginWrap);
        }
    }, true)

    if (sideLoginWrap) {
        sideLoginWrap.addEventListener('click', (e) => {
            e.stopPropagation();
        });
    }
});


document.addEventListener('DOMContentLoaded', (event) => {
    let searchTrigger = document.querySelector('#search_trigger');
    let mainHeaderSearch = document.querySelector('#mainHeader__search');
    let searchInput = document.querySelector('#ajax-search');

    let insideContainer = false;

    if (searchTrigger && mainHeaderSearch && searchInput) {
        searchTrigger.addEventListener('click', function (event) {
            mainHeaderSearch.classList.add('open-search');
            searchInput.focus();
            event.stopPropagation();
        });

        mainHeaderSearch.addEventListener('mousedown', function () {
            insideContainer = true;
        });

        document.addEventListener('mouseup', function () {
            if (!insideContainer) {
                mainHeaderSearch.classList.remove('open-search');
            }
            insideContainer = false;
        });

        mainHeaderSearch.addEventListener('click', function (event) {
            event.stopPropagation();
        });
    }
});


document.addEventListener('DOMContentLoaded', function () {
    const favTrigger = document.getElementById('fav_trigger');
    const favWrap = document.getElementById('fav__wrap');
    const closeButtons = document.querySelectorAll('.side__box--close');
    const body = document.body;

    if (favTrigger && favWrap) {
        favTrigger.addEventListener('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            favWrap.classList.toggle('open');
            body.classList.toggle('noscroll');
        });

        document.addEventListener('click', function (event) {
            if (!favWrap.contains(event.target) && favWrap.classList.contains('open')) {
                favWrap.classList.remove('open');
                body.classList.remove('noscroll');
            }
        });

        closeButtons.forEach(function (button) {
            button.addEventListener('click', function () {
                if (favWrap.classList.contains('open')) {
                    favWrap.classList.remove('open');
                    body.classList.remove('noscroll');
                }
            });
        });
    }
});

document.addEventListener("DOMContentLoaded", function () {
    var menuItems = document.querySelectorAll('nav .main-navigation li.menu-item-has-children');

    menuItems.forEach(function (menuItem) {
        menuItem.addEventListener('click', function (e) {
            // Check if the clicked item is already active
            if (menuItem.classList.contains('active-element')) {
                menuItem.classList.remove('active-element');
                let submenu = menuItem.querySelector('.sub-menu');
                if(submenu !== null) submenu.classList.remove('sub-menu-open')
            } else {
                // If it's not active, remove active class from all and add to the clicked one
                removeActiveClassFromAll();
                if (menuItem.querySelector('a').getAttribute('href') === "#") {
                    // e.preventDefault();
                    menuItem.classList.add('active-element');
                }
            }
        });
    });

    document.addEventListener('click', function (event) {
        if (!event.target.closest('nav .main-navigation')) {
            removeActiveClassFromAll();
        }
    });

    function removeActiveClassFromAll() {
        menuItems.forEach(function (menuItem) {
            let submenu = menuItem.querySelector('.sub-menu');
            if(submenu !== null) submenu.classList.remove('sub-menu-open')
            menuItem.classList.remove('active-element');
        });
    }
});

// home slider
let sliderEl = document.querySelector('.hero-slider');
if (sliderEl !== null) {
    var slider = tns({
        container: '.hero-slider',
        items: 1,
        center: true,
        gutter: 16,
        mouseDrag: true,
        controls: true,
        navPosition: 'bottom',
        nav: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayHoverPause: true,
        controlsText: ["<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-arrow-left-short\" viewBox=\"0 0 16 16\">\n" +
        "  <path fill-rule=\"evenodd\" d=\"M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z\"/>\n" +
        "</svg>", "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-arrow-right-short\" viewBox=\"0 0 16 16\">\n" +
        "  <path fill-rule=\"evenodd\" d=\"M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z\"/>\n" +
        "</svg>"],
        responsive: {
            992: {
                gutter: 16,
            },
            768: {
                gutter: 16,
            },
            0: {
                gutter: 8,
            }
        }
    });
}

// footer nav
document.addEventListener('DOMContentLoaded', function () {
    const dropdownHeaders = document.querySelectorAll('.mainFooter__nav > strong');

    dropdownHeaders.forEach(header => {
        header.addEventListener('click', function () {
            const parent = this.parentNode;
            dropdownHeaders.forEach(otherHeader => {
                if (otherHeader !== header) {
                    otherHeader.parentNode.classList.remove('open');
                }
            });
            parent.classList.toggle('open');
        });
    });
    document.addEventListener('click', function (event) {
        if (!event.target.closest('.mainFooter__nav')) {
            dropdownHeaders.forEach(header => {
                header.parentNode.classList.remove('open');
            });
        }
    });
});

// share
document.addEventListener('DOMContentLoaded', (event) => {
    const shareTrigger = document.getElementById('shareTrigger');
    const shareIconsDiv = document.getElementById('shareIcons');
    const fbShare = document.getElementById('fbShare');
    const pinterestShare = document.getElementById('pinterestShare');
    const twitterShare = document.getElementById('twitterShare');
    const emailShare = document.getElementById('emailShare');

    if (shareTrigger) {
        const shareImg = shareTrigger.querySelector('img');
        if (shareImg) {
            shareImg.addEventListener('click', function (e) {
                e.preventDefault();
            });
        }

        shareTrigger.addEventListener('click', function (e) {
            e.preventDefault();

            if (shareIconsDiv.style.display === 'none' || !shareIconsDiv.style.display) {
                shareIconsDiv.style.display = 'flex';
            } else {
                shareIconsDiv.style.display = 'none';
            }

            let postUrl = encodeURIComponent(window.location.href);
            let postTitle = encodeURIComponent(document.title);

            // Facebook
            fbShare.href = `https://www.facebook.com/sharer/sharer.php?u=${postUrl}`;

            // Pinterest
            let postImage = encodeURIComponent(pinterestShare.getAttribute('data-image'));
            pinterestShare.href = `https://www.pinterest.com/pin/create/button/?url=${postUrl}&media=${postImage}&description=${postTitle}`;

            // Twitter
            twitterShare.href = `https://twitter.com/intent/tweet?text=${postTitle}&url=${postUrl}`;

            // Email
            emailShare.href = `mailto:?subject=${postTitle}&body=Check out this post: ${postUrl}`;
        });
    }

    if (shareIconsDiv) {
        document.addEventListener('click', function (event) {
            let isClickInside = shareIconsDiv.contains(event.target) || event.target.id === 'shareTrigger';

            if (!isClickInside && shareIconsDiv.style.display === 'flex') {
                shareIconsDiv.style.display = 'none';
            }
        });
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('#mainHeader');
    const shortcuts = document.querySelector('.productWrap__shortcuts_');

    if (header && shortcuts) {
        const offset = 100;
        let headerHeight = header.offsetHeight;

        shortcuts.addEventListener('click', (e) => {
            if (e.target.tagName === 'A') {
                e.preventDefault();
                const href = e.target.getAttribute('href');
                const targetSection = document.querySelector(href);
                if (targetSection) {
                    const sectionTop = targetSection.offsetTop - headerHeight - offset;
                    window.scrollTo({
                        top: sectionTop,
                        behavior: 'smooth'
                    });
                }
            }
        });

        function debounce(func, wait) {
            let timeout;
            return function executedFunction(...args) {
                const later = () => {
                    clearTimeout(timeout);
                    func(...args);
                };
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
            };
        }

        const toggleStickyClass = debounce(() => {
            const shortcutsPosition = shortcuts.getBoundingClientRect().top;
            if (shortcutsPosition <= headerHeight && !shortcuts.classList.contains("sticky")) {
                shortcuts.classList.add("sticky");
            } else if (shortcutsPosition > headerHeight && shortcuts.classList.contains("sticky")) {
                shortcuts.classList.remove("sticky");
            }
        }, 10);

        window.addEventListener('scroll', () => {
            headerHeight = header.offsetHeight;
            toggleStickyClass();

            document.querySelectorAll('.productWrap__shortcuts a').forEach((link) => {
                const sectionId = link.getAttribute('href');
                if (sectionId) {
                    const section = document.querySelector(sectionId);
                    if (section) {
                        const sectionTop = section.offsetTop - offset;
                        const sectionHeight = section.offsetHeight;
                        const scrollPosition = window.pageYOffset;

                        if (scrollPosition >= sectionTop - headerHeight && scrollPosition < sectionTop + sectionHeight - headerHeight) {
                            document.querySelectorAll('.productWrap__shortcuts a').forEach((otherLink) => {
                                otherLink.classList.remove('active-link');
                            });
                            link.classList.add('active-link');
                        }
                    }
                }
            });
        });
    }
});

let sliderRev = document.querySelector('.reviews__slider--inner');
if (sliderRev !== null && window.innerWidth > 575) {
    var slider = tns({
        container: '.reviews__slider--inner',
        items: 3,
        // center: true,
        edgePadding: 46,
        gutter: 32,
        loop: false,
        mouseDrag: false,
        fixedWidth: 450,
        // viewportMax: true,
        autoWidth: false,
        autoplay: false,
        autoplayHoverPause: true,
        autoplayTimeout: 3500,
        autoplayButtonOutput: false,
        controls: false,
        navPosition: 'bottom',
        rewind: true,
        nav: false,
        controlsText: ["<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-arrow-left-short\" viewBox=\"0 0 16 16\">\n" +
        "  <path fill-rule=\"evenodd\" d=\"M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z\"/>\n" +
        "</svg>", "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-arrow-right-short\" viewBox=\"0 0 16 16\">\n" +
        "  <path fill-rule=\"evenodd\" d=\"M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z\"/>\n" +
        "</svg>"],
        responsive: {
            768: {
                mouseDrag: true,
                controls: true,
            },
            0: {
                fixedWidth: false,
                controls: false,
            }
        }
    });
}

if (document.querySelector('.productGallery-slider')) {
    var slider = tns({
        container: '.productGallery-slider',
        items: 1,
        center: true,
        edgePadding: 0,
        gutter: 0,
        mouseDrag: false,
        autoWidth: false,
        autoplay: false,
        autoplayButtonOutput: false,
        autoplayHoverPause: true,
        autoplayTimeout: 3500,
        autoHeight: true,
        controls: true,
        navPosition: 'bottom',
        nav: true,
        controlsText: ["<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-arrow-left-short\" viewBox=\"0 0 16 16\">\n" +
        "  <path fill-rule=\"evenodd\" d=\"M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z\"/>\n" +
        "</svg>", "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-arrow-right-short\" viewBox=\"0 0 16 16\">\n" +
        "  <path fill-rule=\"evenodd\" d=\"M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z\"/>\n" +
        "</svg>"],
        responsive: {
            768: {
                mouseDrag: true,
            }
        }
    });
}

document.addEventListener("DOMContentLoaded", function () {
    const specsDetails = document.getElementById("specs__details");
    const showMoreBtn = document.querySelector(".collapseSpecs");

    if (specsDetails && showMoreBtn) {
        showMoreBtn.addEventListener("click", function () {
            if (specsDetails.style.maxHeight !== "none") {
                specsDetails.style.maxHeight = "none";
                specsDetails.classList.add("all-items-shown");
                this.textContent = "Zwiń specyfikację";
            } else {
                specsDetails.style.maxHeight = "400px";
                specsDetails.classList.remove("all-items-shown");
                this.textContent = "Rozwiń pełną specyfikację";
                specsDetails.scrollIntoView({behavior: 'smooth', block: 'start'});
            }
        });
    }
});

// Reviews

document.addEventListener('DOMContentLoaded', function () {
    const reviewTrigger = document.getElementById('review__trigger');
    const modalWrap = document.querySelector('.review__modal--wrap');
    const closeReview = document.querySelector('.close__review');

    if (reviewTrigger && modalWrap && closeReview) {
        reviewTrigger.addEventListener('click', (e) => {
            e.preventDefault();
            modalWrap.classList.add('show');
        });

        closeReview.addEventListener('click', () => {
            modalWrap.classList.remove('show');
        });
    }


    // Mobile Reviews
    var reviewsSliderInner = document.querySelector('.reviews__slider--inner');
    var showAllButton = document.getElementById('review__showAll');
    var reviewsSection = document.getElementById('reviews');

    if (reviewsSliderInner && showAllButton && reviewsSection) {
        showAllButton.addEventListener('click', function (event) {
            event.preventDefault();

            reviewsSliderInner.classList.toggle('rs_expanded');
            if (reviewsSliderInner.classList.contains('rs_expanded')) {
                showAllButton.textContent = 'Zwiń opinie';
            } else {
                showAllButton.textContent = 'Pokaż wszystkie opinie';
                reviewsSection.scrollIntoView({behavior: 'smooth'});
            }
        });
    }
});


// FAQ

document.querySelectorAll('.faq__question').forEach(item => {
    item.addEventListener('click', event => {
        event.target.parentNode.classList.toggle('open');
    });
});

document.addEventListener('DOMContentLoaded', function () {
    var scrollLinks = document.querySelectorAll('a.scroll');

    function scrollToElement(e) {
        e.preventDefault();
        var targetId = this.getAttribute('href').substring(1);
        var targetElement = document.getElementById(targetId);

        if (targetElement) {
            var offset = 120;
            var targetPosition = targetElement.offsetTop - offset;

            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });
        }
    }

    scrollLinks.forEach(function (link) {
        link.addEventListener('click', scrollToElement);
    });
});


// AOS

AOS.init();

let fv = document.querySelector('.js-fv-checkbox');
if (fv !== null) {
    let fields_to_show = fv.closest('.woocommerce-billing-fields').querySelectorAll('.js-fvat-to-show');
    if (fields_to_show !== null) {
        fields_to_show.forEach(field => {
            field.style.display = 'none';
        });
    }
    let fields_to_hide = fv.closest('.woocommerce-billing-fields').querySelectorAll('.js-fvat-to-hide');
    if (fields_to_hide !== null) {
        fields_to_hide.forEach(field => {
            let label = field.querySelector('label');
            if (label !== null) {
                let optional = label.querySelector('span');
                if (optional !== null) {
                    optional.remove();
                    let abbr = document.createElement('abbr');
                    abbr.classList.add('required');
                    abbr.setAttribute('title', 'wymagane');
                    abbr.innerText = '*';
                    label.appendChild(abbr);
                }
            }
        });
    }
    fv.addEventListener('change', () => {
        if (fv.checked) {
            fields_to_show.forEach(field => {
                field.style.display = 'block';

                if (!field.classList.contains('validate-required')) {
                    field.classList.add('validate-required');
                }

                let label = field.querySelector('label');
                if (label !== null) {
                    let optional = label.querySelector('span');
                    if (optional !== null) {
                        optional.remove();
                        let abbr = document.createElement('abbr');
                        abbr.classList.add('required');
                        abbr.setAttribute('title', 'wymagane');
                        abbr.innerText = '*';
                        label.appendChild(abbr);
                    }
                }
            });

            if (fields_to_hide !== null) {
                fields_to_hide.forEach(field => {
                    if (field.classList.contains('validate-required')) {
                        field.classList.remove('validate-required');
                    }

                    let label = field.querySelector('label');
                    if (label !== null) {
                        let abbr = label.querySelector('abbr');
                        if (abbr !== null) {
                            abbr.remove();
                            let optional = document.createElement('span');
                            optional.classList.add('optional');
                            optional.innerText = '(opcjonalne)';
                            label.appendChild(optional);
                        }
                    }
                });
            }
        } else {
            fields_to_show.forEach(field => {
                field.style.display = 'none';
            });

            if (fields_to_hide !== null) {
                fields_to_hide.forEach(field => {
                    if (!field.classList.contains('validate-required')) {
                        field.classList.add('validate-required');
                    }

                    let label = field.querySelector('label');
                    if (label !== null) {
                        let optional = label.querySelector('span');
                        if (optional !== null) {
                            optional.remove();
                            let abbr = document.createElement('abbr');
                            abbr.classList.add('required');
                            abbr.setAttribute('title', 'wymagane');
                            abbr.innerText = '*';
                            label.appendChild(abbr);
                        }
                    }
                });
            }
        }
    });
}


let submenus = document.querySelectorAll('.js-sub-menu-image');
if(submenus.length > 0) {
    submenus.forEach(submenu => {
        console.log(submenu);
        new subMenuImage(submenu);
    })
}

let scrollSpy = document.querySelector('.js-scroll-spy');
if(scrollSpy !== null) {
    new ScrollSpy(scrollSpy);
}

let contact_form = document.querySelector('.js-contact-form');

if (contact_form !== null) {
    let loader = contact_form.querySelector('[data-element="loader"]');
    let recaptcha_key = contact_form.getAttribute('data-recaptcha');
    let pristine = new Pristine(contact_form, {
        classTo: 'js-per',
        errorTextParent: 'js-per',
    });


    new Form(contact_form, {
        action: '/wp-json/netivo/v1/contact_form',
        data: el => {
            return new Promise((resolve, reject) => {
                let data = {
                    name: '',
                    email: '',
                    message: '',
                    nonce: '',
                    recaptcha: '',
                };

                let form_first_name = el.querySelector('[name="name"]'),
                    form_email = el.querySelector('[name="email"]'),
                    form_message = el.querySelector('[name="message"]'),
                    form_nonce = el.querySelector('[name="nonce"]');

                if (form_first_name !== null) data.name = form_first_name.value;
                if (form_email !== null) data.email = form_email.value;
                if (form_message !== null) data.message = form_message.value;
                if (form_nonce !== null) data.nonce = form_nonce.value;

                grecaptcha.ready(function() {
                    grecaptcha.execute(recaptcha_key, {action: 'contact'}).then(function (token) {
                        data.recaptcha = token;
                        resolve(data);
                    });
                });
            });
        },
        beforeSubmit: (el, hashed) => {
            let is_valid = pristine.validate();
            if(is_valid){
                loader.classList.add('loader--show-in-form');
                let formResponse = contact_form.querySelector('[data-element="response"]');
                formResponse.style.display = 'flex';
            } else {
                let top = el.querySelector('.has-danger').getBoundingClientRect().top + window.pageYOffset;
                //console.log(top);
                window.scrollTo({top: top - 100, behavior: "smooth"});
            }
            return is_valid;
        },
        success: (el, response) => {
            let formResponse = contact_form.querySelector('[data-element="response"]');
            let formSuccess = contact_form.querySelector('[data-response="success"]');
            loader.classList.remove('loader--show-in-form');
            formSuccess.innerText = response.message;
            formSuccess.style.display = 'block';
            setTimeout(() => {
                el.querySelector('[name="name"]').value = '';
                el.querySelector('[name="email"]').value = '';
                el.querySelector('[name="message"]').value = '';
                el.querySelector('#disclaimer').checked = false;
                formResponse.style.display = 'none';
            }, 6000);
        },
        error: (el, response) => {
            //console.log(response);
            let formResponse = contact_form.querySelector('[data-element="response"]');
            let formError = contact_form.querySelector('[data-response="error"]');
            loader.classList.remove('loader--show-in-form');
            formError.innerText = response;
            setTimeout(() => {
                formResponse.style.display = 'none';
            }, 6000);
        }
    });
}
let data_filters_hidden = document.querySelectorAll('.js-hidden-filter-options');
if (data_filters_hidden.length > 0) {
    data_filters_hidden.forEach(filter => {
        new HiddenFilters(filter);
    });
}

let tpay_calculator = document.querySelector('.js-tpay-calculator');
if (tpay_calculator !== null) {
    new TpayCalculator(tpay_calculator);
}

let backinstock_form = document.querySelector('.js-backinstock-notify');
if (backinstock_form != null ) {
    let loader = backinstock_form.querySelector('[data-element="loader"]');
    let recaptcha_key = backinstock_form.getAttribute('data-recaptcha');
    console.log(recaptcha_key);
    let pristine = new Pristine(backinstock_form, {
        classTo: 'js-per',
        errorTextParent: 'js-per',
    });

    new Form(backinstock_form, {
        action: '/wp-json/netivo/v1/backinstock_form',
        data: el => {
            return new Promise((resolve, reject) => {
                let data = {
                    email: '',
                    product: '',
                    nonce: '',
                    recaptcha: '',
                };

                let form_email = el.querySelector('[name="email"]'),
                    form_product = el.querySelector('[name="product"]'),
                    form_nonce = el.querySelector('[name="nonce"]');

                if (form_email !== null) data.email = form_email.value;
                if (form_product !== null) data.product = form_product.value;
                if (form_nonce !== null) data.nonce = form_nonce.value;

                grecaptcha.ready(function() {
                    grecaptcha.execute(recaptcha_key, {action: 'backinstock'}).then(function (token) {
                        data.recaptcha = token;
                        resolve(data);
                    });
                });
            });
        },
        beforeSubmit: (el, hashed) => {
            let is_valid = pristine.validate();
            if(is_valid){
                loader.classList.add('loader--show-in-form');
                let formResponse = backinstock_form.querySelector('[data-element="response"]');
                formResponse.style.display = 'flex';
            } else {
                let top = el.querySelector('.has-danger').getBoundingClientRect().top + window.pageYOffset;
                //console.log(top);
                window.scrollTo({top: top - 100, behavior: "smooth"});
            }
            return is_valid;
        },
        success: (el, response) => {
            let formResponse = backinstock_form.querySelector('[data-element="response"]');
            let formSuccess = backinstock_form.querySelector('[data-response="success"]');
            loader.classList.remove('loader--show-in-form');
            formSuccess.innerText = response.message;
            formSuccess.style.display = 'block';
            setTimeout(() => {
                el.querySelector('[name="email"]').value = '';
                el.querySelector('[name="product"]').value = '';
                el.querySelector('#disclaimer').checked = false;
                formResponse.style.display = 'none';
            }, 6000);
        },
        error: (el, response) => {
            //console.log(response);
            let formResponse = backinstock_form.querySelector('[data-element="response"]');
            let formError = backinstock_form.querySelector('[data-response="error"]');
            loader.classList.remove('loader--show-in-form');
            formError.innerText = response;
            setTimeout(() => {
                formResponse.style.display = 'none';
            }, 6000);
        }
    });
}