class Menu {
    constructor(selector) {
        this.selector = selector;
        let menu_items = this.selector.querySelectorAll('.menu-item.menu-item-has-children');

        console.log(menu_items);

        this.currentItem = null;
        this.currentSubItem = null;

        menu_items.forEach(item => {
            let submenu = item.closest('.sub-menu');
            let is_submenu = false;
            if(submenu !== null) is_submenu = true;
            let link = item.querySelector('a');
            link.addEventListener('click', e => {
                e.preventDefault();
                if(is_submenu) {
                    if(this.currentSubItem !== null && this.currentSubItem !== item) {
                        this.currentSubItem.classList.remove('open-item');
                    }
                    item.classList.toggle('open-item');
                    this.currentSubItem = item.classList.contains('open-item') ? item : null;
                } else {
                    if(this.currentItem !== null && this.currentItem !== item) {
                        this.currentItem.classList.remove('open-item');
                        if(this.currentSubItem !== null) {
                            this.currentSubItem.classList.remove('open-item');
                        }
                    }
                    item.classList.toggle('open-item');
                    this.currentItem = item.classList.contains('open-item') ? item : null;
                }
            });
        });
    }
}

export default Menu;