class subMenuImage {
    constructor(selector) {
        this.selector = selector;
        this.default = null;

        this._mouseover = this._mouseover.bind(this);
        this._mouseout = this._mouseout.bind(this);

        let parent = this.selector.parentNode;
        let def_img = parent.querySelector('[data-default]');
        console.log(def_img);
        if(def_img !== null) {
            let img = def_img.getAttribute('data-image');
            console.log(img);
            if(img !== null) {
                this.default = img;
                this.selector.style.setProperty('--submenu-image', 'url('+this.default+')');
            }
        }

        let links = this.selector.querySelectorAll('[data-image]');
        if(links.length > 0){
            links.forEach(link => {
                link.addEventListener('mouseover', this._mouseover);
                link.addEventListener('mouseout', this._mouseout);
            });
        }
    }

    _mouseover(event) {
        event.preventDefault();
        let image = event.target.getAttribute('data-image');
        console.log(image);
        if(image !== null) {
            this.selector.style.setProperty('--submenu-image', 'url(' + image + ')');
        }
    }
    _mouseout(event) {
        event.preventDefault();
        console.log('out');
        if(this.default !== null) {
            this.selector.style.setProperty('--submenu-image', 'url('+this.default+')');
        } else {
            this.selector.style.removeProperty('--submenu-image');
        }
    }
}

export default subMenuImage;