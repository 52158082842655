class ProductBox {
    constructor(selector, fav, comp, cart) {
        this.selector = selector;
        this.product_box = selector.querySelectorAll('.js-product-box');
        this.products = [];
        this.product_data = [];
        if(this.product_box.length > 0) {
            this.product_box.forEach(pr => {
               let pid = pr.getAttribute('data-id');
               if(pid !== undefined) {
                   if(!this.products.includes(pid)) this.products.push(pid);
               }
            });
        }
        if(this.products.length > 0) {
            this._getFromApi().then(result => {
                if(result.status === 'success') {
                    this.product_box.forEach(prb => {
                        let pid = prb.getAttribute('data-id');
                        if(result.products.hasOwnProperty(pid)){
                            let tmp = document.createElement('div');
                            tmp.innerHTML = result.products[pid];

                            if(fav !== null) {
                                let add_to_fav = tmp.querySelector('.js-add-to-favourites');
                                console.log(add_to_fav);
                                if(add_to_fav !== null) {
                                    add_to_fav.addEventListener('click', fav.addOrRemoveFromFavourites)
                                }
                            }
                            if(comp !== null) {
                                let add_to_comp = tmp.querySelector('.js-add-to-compare');
                                if(add_to_comp !== null) {
                                    add_to_comp.addEventListener('click', fav.addOrRemoveFromComparison)
                                }
                            }
                            if(cart !== null) {
                                let add_to_cart = tmp.querySelector('.ajax_add_to_cart');
                                if(add_to_cart !== null) {
                                    add_to_cart.addEventListener('click', cart.addToCart);
                                }
                            }
                            prb.replaceWith(tmp.querySelector('.singleproduct'));
                        }
                    });
                } else {
                    console.log(result.error);
                }
            }).catch(error => {
               console.log(error);
            });
        }
    }

    _getFromApi() {
        const body_data = new FormData();
        body_data.append('action', 'nt_product_box');
        this.products.forEach(pr => {
           body_data.append('product_id[]', pr);
        });

        const body_params = new URLSearchParams(body_data);
        return fetch('/wp-admin/admin-ajax.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Cache-Control': 'no-cache',
            },
            body: body_params
        }).then((response) => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
}

export default ProductBox;